* {
  box-sizing: border-box;
}
.app {
  h4 {
    font-size: 64px;
    font-weight: 800;
    margin: 0;
    text-align: center;
  }
  h6 {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    text-align: center;
  }
  .invisible-button {
    padding: 15px 39px;
    background: inherit;
    border: 0;
    font-size: 18px;
    font-weight: 500;
    color: #040404;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .main-button {
    margin-left: 5px;
    padding: 19px 39px;
    background: #00C74D;
    border: 0;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 500;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    &:hover {
      background: #00E258;
    }
  }
  .button-disabled {
    background: #93A0AE;
    pointer-events: none;
    cursor: auto;
  }
  .app-header {
    position: sticky;
    top: 30px;
    margin: 0 45px;
    left: 45px;
    right: 45px;
    border-radius: 70px;
    z-index: 3;
    backdrop-filter: blur(32px);
    background-color: rgb(0 0 0 / 25%);
    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 40px;
    }
    &__menu {
      display: flex;
      gap: 40px;
      align-items: center;
      color: white;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 0.5px;
      padding-left: 10%;
      &--item {
        cursor: pointer;
      }
    }
    &__actions {
      display: flex;
      gap: 10px;
      .main-button {
        padding: 10px 30px;
      }
      .logIn {
        color: #00C74D;
        background: white;
      }
      .main-button {
        text-transform: uppercase;
        font-weight: 700;
        margin: 0;
      }
    }
    &__logo {
      width: 125px;
      height: 44px;
    }
  }
  .app-main {
    position: relative;
    padding: 0 64px 200px 64px;
    color: #040404;
    overflow: hidden;
    margin-top: -75px;
    &__decoration1 {
      position: absolute;
      width: 300px;
      right: 0;
      filter: drop-shadow(0 0 250px rgba(0,199,77, 0.5))
    }
    &__decoration2 {
      position: absolute;
      width: 200px;
      left: 0;
      top: 20%;
      filter: drop-shadow(0 0 250px rgba(0,199,77, 0.5))
    }
    .screen {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      &__logo {
        width: 45%;
        margin-bottom: 130px;
        position: relative;
        z-index: 2;
        filter: drop-shadow(0 0 250px rgba(0,199,77, 0.5));
        min-width: 320px;
      }
      &__decoration {
        width: 11%;
        position: absolute;
        top: 30%;
        left: 20%;
      }
    }
    .welcome {
      max-width: 1000px;
      margin: 0 auto 140px auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #040404;
      position: relative;
      &__logo {
        width: 250px;
        min-width: 250px;
        padding-right: 30px;
        filter: drop-shadow(0 0 250px rgba(0,199,77, 0.5));
        &::after {
          content: '';
          position: absolute;
          top: 25px;
          bottom: 25px;
          width: 2px;
          background: #B8C5D0;
          right: 0;
        }
      }
      &__name {
        margin-bottom: 10px;
      }
      &__text {
        font-size: 16px;
        color: #040404;
        margin-left: 50px;
      }
    }
    .content-header {
      margin-bottom: 50px;
      &--text {
        text-align: center;
      }
      &--name {
        position: relative;
        .statistics__header--character {
          position: absolute;
          top: -55px;
          right: 35px;
          width: 70px;
        }
      }
    }
    .statistics {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 200px;
      position: relative;
      &__decoration {
        position: absolute;
        right: calc(50% - 700px);
        top: 250px;
        z-index: -1;
        width: 40%;
      }
      &__container {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        justify-content: center;
        align-items: center;
        margin: auto;
        max-width: 970px;
        &--character2 {
          position: absolute;
          width: 120px;
          left: -35px;
          bottom: 75px;
          z-index: 0;
        }
        .statistics__item {
          max-width: 400px;
          min-width: 280px;
          height: 150px;
          background: #EEF1F6;
          border-radius: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 20px;
          position: relative;
          &--character1 {
            position: absolute;
            top: -40px;
            right: -20px;
            width: 80px;
            z-index: 1;
          }
          &--value {
            font-size: 36px;
            margin-bottom: 10px;
          }
          &--name {
            font-size: 14px;
            color: #93A0AE;
            text-transform: uppercase;
          }
        }
      }
    }
    .slider {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 50px;
      height: 500px;
    }
    .action {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 90px 0;
      margin: 0 100px;
      border: 2px solid #00C74D;
      border-radius: 20px;
      &__img {
        max-width: 700px;
        min-width: 300px;
        margin-bottom: 30px;
      }
    }
  }
  .affiliate-main {
    padding: 0 64px;
    .offer {
      width: 100%;
      position: relative;
      &__decoration4 {
        width: 60px;
        height: 60px;
        background: #00C74D;
        border-radius: 50%;
        filter: blur(20px);
        position: absolute;
        bottom: -30px;
        right: 268px;
      }
      &__decoration5 {
        width: 200px;
        border-radius: 50%;
        position: absolute;
        filter: blur(7px);
        bottom: -150px;
        left: 107px;
      }
      .offer-header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        &__text {
          letter-spacing: 10px;
          text-transform: uppercase;
          margin-top: 15px;
        }
        &__decoration {
          width: 60px;
          height: 60px;
          background: #00C74D;
          border-radius: 50%;
          filter: blur(30px);
          position: absolute;
          top: 0;
          left: 25%;
        }
      }
      .offer-list {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 50px;
        position: relative;
        margin-bottom: 200px;
        &__item {
          background: #EEF1F6;
          width: 400px;
          height: 410px;
          border-radius: 30px;
          padding: 40px;
          display: flex;
          flex-direction: column;
        }
        &__name {
          font-size: 24px;
          margin-bottom: 50px;
        }
        &__text {
          font-size: 18px;
          color: #93A0AE;
        }
        &__decoration3 {
          margin-top: 10px;
          margin-bottom: 40px;
          width: 80px;
        }
        &__decoration2 {
          margin-top: 10px;
          margin-bottom: 30px;
          width: 70px;
        }
        &__decoration1 {
          margin-top: 10px;
          margin-bottom: 18px;
          width: 80px;
        }
      }
    }
    .product {
      margin-bottom: 200px;
      &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        margin-bottom: 200px;
        &--text {
          letter-spacing: 10px;
        }
        &::after {
          content: '';
          width: 400px;
          height: 460px;
          background: #00C74D;
          position: absolute;
          top: 60px;
          left: 50%;
          transform: translateX(-50%);
          z-index: -1;
          border-radius: 50%;
          filter: blur(200px);
        }
      }
      &__main {
        display: flex;
        flex-wrap: wrap;
        &-item {
          width: calc((100% / 4) - 16px);
          margin: 8px;
          min-width: 280px;
          &--name {
            font-size: 16px;
            letter-spacing: 2px;
            font-weight: 600;
            margin-bottom: 10px;
            text-transform: uppercase;
          }
          &--text {
            color: #93A0AE;
            font-size: 18px;
          }
        }
      }
    }
    .comments {
      &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 100px;
        &--text {
          letter-spacing: 10px;
          margin-bottom: 15px;
        }
      }
      &__main {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        &-item {
          width: calc(50% - 16px);
          margin: 8px 8px 100px 8px;
          max-width: 600px;
          position: relative;
          min-width: 300px;
          &--decoration {
            position: absolute;
            top: -70px;
            left: 0;
            width: 113px;
            z-index: -1;
          }
          &--link {
            font-size: 14px;
            font-weight: 700;
            color: black;
            &:hover {
              color: #00C74D;
            }
          }
          &--text {
            font-size: 18px;
            color: #93A0AE;
            margin-bottom: 10px;
          }
          &--name {
            text-transform: uppercase;
            font-size: 12px;
            letter-spacing: 2px;
            span {
              font-size: 14px;
              font-weight: 700;
            }
          }
        }
      }
    }
    .team {
      margin-bottom: 200px;
      &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        &--text {
          letter-spacing: 10px;
          margin-bottom: 15px;
        }
      }
      &__main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &-item {
          background: #EEF1F6;
          margin: 60px 8px 8px 8px;
          max-width: 800px;
          height: 188px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          border-radius: 30px;
          min-width: 300px;
          width: 100%;
          padding: 60px 70px;
          @media screen and (max-width: 700px) {
            flex-direction: column;
            height: auto;
            padding: 40px 50px;
          }
          &--contact {
            display: flex;
            justify-content: center;
            flex-direction: column;
            gap: 10px;
            @media screen and (max-width: 700px) {
              align-items: center;
              gap: 20px;
            }
          }
          &--info {
            display: flex;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: 700px) {
              flex-direction: column;
              height: auto;
              margin-bottom: 50px;
            }
          }
          &--info-container {
            display: flex;
            justify-content: center;
            flex-direction: column;
          }
          &--photo {
            width: 61px;
            height: 61px;
            margin-right: 30px;
            border-radius: 16px;
            overflow: hidden;
            @media screen and (max-width: 700px) {
              margin-right: 0;
              margin-bottom: 20px;
            }
          }
          &--name {
            font-size: 24px;
            font-weight: 500;
            text-align: center;
          }
          &--position {
            font-size: 18px;
            color: #93A0AE;
            font-weight: 500;
            text-align: center;
          }
          &--mail {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 500;
            color: #00C74D;
            img {
              width: 18px;
              margin-right: 10px;
            }
          }
          &--skype {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 500;
            color: #0FA5E9;
            img {
              width: 18px;
              margin-right: 10px;
            }
          }
          &--telegram {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 500;
            color: #0FA5E9;
            img {
              width: 18px;
              margin-right: 10px;
            }
          }
        }
      }
    }
    .contactUs {
      margin-bottom: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .team__header {
        margin-bottom: 20px;
        text-transform: uppercase;
      }
      .input-form {
        max-width: 460px;
        min-width: 300px;
        .input-info-container {
          display: flex;
          gap: 20px;
          margin-bottom: 20px;
          @media screen and (max-width: 500px) {
            display: block;
          }
        }
        .input-container {
          display: flex;
          flex-direction: column;
          .input-label {
            font-size: 10px;
            font-weight: 700;
            color: #93A0AE;
            margin-bottom: 5px;
            margin-left: 20px;
          }
          .input__info {
            height: 42px;
            width: 100%;
            font-size: 12px;
            color: #484E55;
            font-weight: 600;
            border-radius: 8px;
            border: 1px solid white;
            background: #DBE0E7;
            padding: 0 20px;
            &::placeholder {
              color: #090909;
            }
          }
          .input__text {
            height: 220px;
            width: 100%;
            font-size: 12px;
            color: #484E55;
            font-weight: 600;
            border-radius: 8px;
            border: 1px solid white;
            background: #DBE0E7;
            padding: 15px 20px;
            resize: none;
            &::placeholder {
              color: #090909;
              font-size: 12px;
              font-weight: 600;
            }
          }
          .input__error {
            border: 1px solid #F02121;
          }
          .error-message {
            color: #F02121;
            margin: 10px 20px;
          }
        }
        .input-button {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 25px;
        }
      }
    }
  }
  @media screen and (max-width: 1300px) {
    .app-main {
      &__decoration2 {
        display: none;
      }
    }
  }
  @media screen and (max-width: 1150px) {
    .app-main {
      margin-top: -55px;
      &--menu-open {
        margin-top: -481px;
      }
    }
    h4 {
      font-size: 50px;
    }
    .app-header {
      margin: 0 5px;
      top: 5px;
      backdrop-filter: blur(20px);
      border-radius: 30px;
      background-color: rgb(0 0 0 / 40%);
      &__container {
        align-items: center;
        padding: 6px 18px;
        height: 52px;
      }
      &__menu {
        display: none;
        &--burger {
          width: 24px;
          height: 16px;
          cursor: pointer;
        }
        &--close {
          width: 24px;
          height: 16px;
          cursor: pointer;
        }
      }
      &__menu-mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        color: white;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 30px;
        letter-spacing: 0.5px;
        padding-top: 30px;
        padding-bottom: 70px;
        &--item {
          cursor: pointer;
        }
        .app-header__actions {
          flex-direction: column;
          gap: 20px;
        }
      }
      &__logo {
        width: 70px;
        height: 27px;
      }
    }
  }
  @media screen and (max-width: 1010px) {
    h4 {
      font-size: 40px;
    }
    h6 {
      font-size: 20px;
    }
      .app-main {
        .statistics {
          &__decoration {
            right: calc(50% - 250px);
            top: -30px;
            min-width: 500px;
          }
          &__container {
            &--character2 {
              position: absolute;
              width: 85px;
              bottom: auto;
              top: -60px;
              left: 10%;
              z-index: 0;
            }
          }
        }
      }
  }
  @media screen and (max-width: 900px) {
    .invisible-button {
      padding: 13px 20px;
      font-size: 12px;
    }
    .main-button {
      padding: 13px 20px;
      font-size: 12px;
      font-weight: 700;
    }
    .app-main {
      padding: 0 10px 0 10px;
      &--menu-open {
        margin-top: -469px;
      }
      .screen {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        &__logo {
          width: 45%;
          margin-bottom: 130px;
          position: relative;
          z-index: 2;
          filter: drop-shadow(0 0 250px rgba(0,199,77, 0.5));
          min-width: 320px;
        }
        &__decoration {
          width: 11%;
          position: absolute;
          top: 5%;
          left: 20%;
        }
      }
      &__decoration1 {
        width: 90px;
        top: 250px;
      }
      .welcome {
        flex-direction: column;
        &__logo {
          padding-right: 0;
          padding-bottom: 30px;
          &::after {
            content: '';
            position: absolute;
            top: auto;
            bottom: 0;
            width: auto;
            height: 2px;
            background: #B8C5D0;
            right: 25px;
            left: 25px;
          }
        }
        &__text {
          margin-left: 0;
          margin-top: 50px;
          max-width: 550px;
          text-align: center;
        }
      }
      .statistics {
        padding-bottom: 100px;
        &__container {
          .statistics__item {
            margin: 10px;

            &--character1 {
              position: absolute;
              top: -40px;
              right: -20px;
              width: 80px;
              z-index: 1;
            }
          }
        }
      }
      .action {
        margin: 0;
        padding: 40px 15px;
        .content-header {
          margin-bottom: 20px;
          &--name {
            margin-top: 15px;
          }
        }
      }
    }
    .affiliate-main {
      padding: 100px 10px 0 10px;
      .offer {
        margin-top: 100px;
        &__decoration4 {
          width: 60px;
          height: 60px;
          bottom: 300px;
          right: 260px;
        }
        .offer-header {
          &__decoration {
            width: 40px;
            height: 40px;
            top: 0;
            left: 0;
          }
        }
        .offer-list {
          &__item {
            width: 300px;
          }
          .listName3 {
            margin-bottom: 75px;
          }
        }
      }
      .promotion {
        margin-bottom: 100px;
        &__header {
          margin-bottom: 30px;
          &--span {
            padding: 5px;
          }
          &--text {
            letter-spacing: 5px;
          }
        }
      }
      .product {
        margin-bottom: 100px;

        &__header {
          margin-bottom: 50px;

          &--text {
            margin-bottom: 30px;
          }

          &::after {
            width: 60vw;
            height: 60vw;
          }
        }
      }
      .comments {
        .comments__header {
          &--text {
            letter-spacing: 5px;
          }
        }
      }
      .team {
        margin-bottom: 100px;
        .team__header {
          &--text {
            letter-spacing: 5px;
          }
          &--name {
            font-size: 35px;
          }
        }
      }
      .contactUs {
        .team__header {
          &--text {
            text-transform: uppercase;
            letter-spacing: 5px;
          }
          &--name {
            margin-bottom: 20px;
          }
        }
      }
      .stats {
        margin: 0 0 150px 0;
        padding: 50px 0 50px 0;
        .team__header {
          margin-bottom: 20px;
        }
      }
    }
  }
  @media screen and (max-width: 460px) {
    .app-main {
      .content-header {
        &--name {
          .statistics__header--character {
            position: absolute;
            top: 35px;
            right: -10px;
            width: 50px;
          }
        }
      }
    }
  }
}

::-webkit-scrollbar-thumb {
  background-color: #484E55;
}

::-webkit-scrollbar-track {
  background-color: #1A1E23;
}

::-webkit-scrollbar {
  width: 5px;
}