@font-face {
  font-family: 'Mona Sans';
  src:
          url('../public/fonts/Mona Sans/Mona-Sans.woff2') format('woff2 supports variations'),
          url('../public/fonts/Mona Sans/Mona-Sans.woff2') format('woff2-variations');
  font-weight: 200 900;
  font-stretch: 75% 125%;
}

body {
  margin: 0;
  font-family: 'Mona Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  width: 100%;
  height: auto;
}
